import First from "../src/img/lots/1.webp";
import Second from "../src/img/lots/2.webp";
import Third from "../src/img/lots/3.webp";
import Fourth from "../src/img/lots/4.webp";
import Fifth from "../src/img/lots/5.webp";
import Six from "../src/img/lots/6.webp";

export const LotsInfo = [
  {
    id: 1,
    name: "Начинка",
    img: First,
  },
  {
    id: 2,
    name: "Don't touch",
    img: Second,
  },
  {
    id: 3,
    name: "Моё!",
    img: Third,
  },
  {
    id: 4,
    name: "Ароматерапия",
    img: Fourth,
  },
  {
    id: 5,
    name: "Утро",
    img: Fifth,
  },
  {
    id: 6,
    name: "Я тебя жду",
    img: Six,
  },
];
